
import { Component, Vue } from 'nuxt-property-decorator';
const validator = require("email-validator");

@Component({
  name: 'PhoneCall'
})
export default class PhoneCall extends Vue {
  public newsletterConfirmDialog = false;
  public subscriptionLoading = false;
  public subscriberEmail: string | null = null;

  public async subscribeToNewsletter() {
    if (!validator.validate(this.subscriberEmail))
      return this.$notify({
        type: "error",
        message: this.$t('error_invalid_email'),
      });

    this.subscriptionLoading = true;

    await this.$store.dispatch('subscribeToNewsletter', this.subscriberEmail)
      .then((res) => {
        this.subscriberEmail = null;
        this.subscriptionLoading = false;
        this.newsletterConfirmDialog = true;
        setTimeout(f => this.newsletterConfirmDialog = false, 3000)
      })
      .catch((e) => {
        this.$notify({
          type: 'error',
          message: this.$t('reset_password_error')
        })
      })
  }
}
