
import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component({
  name: "HeroComponent",
})
export default class HeroComponent extends Vue {
  public get _bgImage(): string {
    return require("~/assets/images/new-bg.svg");
  }
}
