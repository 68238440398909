
import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  name: 'Features'
})
export default class Features extends Vue {
  public features: any[] = [
    {
      heading: this.$t('lender_search'),
      description: this.$t('lender_feature_description'),
      icon: require('~/assets/images/feature-icons/compare-data.svg')
    },
    {
      heading: this.$t('direct_contact'),
      description: this.$t('contact_feature_description'),
      icon: require('~/assets/images/feature-icons/connect.svg')
    },
    {
      heading: this.$t('no_commission'),
      description: this.$t('commission_feature_description'),
      icon: require('~/assets/images/feature-icons/save-money.svg')
    },
    {
      heading: this.$t('independent'),
      description: this.$t('independence_feature_description'),
      icon: require('~/assets/images/feature-icons/interest.svg')
    }
  ]

}
