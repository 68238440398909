
import { Component, Vue } from "nuxt-property-decorator";

@Component({
  name: "FullTestimonials",
})
export default class FullTestimonials extends Vue {
  public get testimonials(): any[] {
    return this.$store.state.testimonials;
  }
}
