
import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component({
  name: "HeroEventComponent",
})
export default class HeroEventComponent extends Vue {
  public get _bgImage(): string {
    return require("~/assets/images/new-bg.svg");
  }
}
