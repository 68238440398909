
import { Component, Vue } from "nuxt-property-decorator";

@Component
export default class FrontpageSearch extends Vue {
  public get staticFields(): any {
    return this.$store.state.static;
  }

  public get fields(): any {
    return this.$store.state.search;
  }

  public get cities(): any[] {
    return this.$store.state.locations?.cities;
  }

  public get offerTypes(): any[] {
    let temp: any[] = [];
    this.staticFields.offerTypes.forEach((o: any) => {
      const obj = {
        ...o,
        text: this.$t(o.key),
      };
      temp.push(obj);
    });
    return temp;
  }

  public getLocationString(event: any): string {
    if (typeof event === "string") {
      return event;
    } else if (!!event?.key) {
      return event.key;
    } else return "";
  }

  public searchCities(query: string): void {
    if (query?.length >= 3) {
      this.$store.dispatch("locations/fetchCities", {
        query: query,
      });
    }
  }

  public updateField(input: any): void {
    if (input.key !== "amount") {
      this.trackFilterUpdate();
    }
    this.$store.commit("search/SET_FIELD", input);
  }

  private trackFilterUpdate(): void {
    //@ts-ignore
    this.$ga.event({
      eventCategory: "Lender Search",
      eventAction: "Lender View Filter",
      eventLabel: "Lender View Filter",
      eventValue: 1,
    });
  }

  public searchLenders(): void {
    this.$router.push({path: this.localePath('/lenders')})
  }
}
