
import { Component, Vue } from "nuxt-property-decorator";
import ContentSection from "~/components/frontpage/content-section.vue";
import HeroComponent from "~/components/frontpage/hero.vue";
import HeroEventComponent from "~/components/frontpage/hero-event.vue";
import Features from "~/components/frontpage/sales-components/features.vue";
import PhoneCall from "~/components/frontpage/sales-components/phone-call.vue";
import FullTestimonials from "~/components/global/full-testimonials.vue";
import FeatureSection from "../components/feature-section.vue";
import FrontpageSearch from "../components/frontpage-search.vue";

@Component({
  name: "Home",
  components: {
    heroComponent: HeroComponent,
    heroEventComponent: HeroEventComponent,
    contentSection: ContentSection,
    fullTestimonials: FullTestimonials,
    features: Features,
    phoneCall: PhoneCall,
    featureSection: FeatureSection,
    frontpageSearch: FrontpageSearch,
  },
})
export default class Home extends Vue {
  head() {
    return {
      meta: [{ hid: "robots", name: "robots", content: "index, follow" }],
      script: [
        {
          type: "application/ld+json",
          innerHTML: `
				{
      "@context": "https://schema.org",
      "@type": "Corporation",
      "name": "FinList GmbH",
      "alternateName": "FinList",
      "url": "https://www.finlist.de",
      "description": "Durch die tagesaktuelle Kreditgeber­datenbank und passgenaue Finanzierung­sanfragen erhalten Sie Ihre Finanzierung schnell und günstig.",
      "logo": "https://www.finlist.de/press-assets/slice.png",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+49 3303 / 5417 240",
        "contactType": "sales",
        "areaServed": ["DE","AT","CH"],
        "availableLanguage": ["en","German"]
      },
      "sameAs": [
        "https://www.linkedin.com/company/finlist-de",
        "https://www.instagram.com/finlist.de/"
      ]
    }
				`,
        },
      ],
      __dangerouslyDisableSanitizers: ["script"],
    };
  }

  public get userIsPremium(): boolean {
    const { boughtPackage, packageEndDate } = this.$store.state?.user ?? { boughtPackage: false, packageEndDate: null };
    return boughtPackage && !!packageEndDate && new Date(packageEndDate).getTime() >= Date.now();
  }

  public get showForLocale() {
    let { locale } = this.$i18n ?? { locale: "de" };
    return locale === "de";
  }

  public get btnSize(): any {
    const size = { xs: "dense", sm: "large", md: "x-large", lg: "x-large", xl: "x-large" }[this.$vuetify.breakpoint.name];
    return size ? { [size]: true } : {};
  }
}
