
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({
	name: 'ContentSection'
})
export default class ContentSection extends Vue {
	@Prop({ required: false, default: false }) readonly fluid!: boolean;
	@Prop({ required: false, default: false }) readonly textRight!: boolean;
	@Prop({ required: false, default: 'transparent' }) readonly bgColor!: string;

}
